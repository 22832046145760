export default {
    ACUnchanged: 'Your last availability check is still valid!',
    ACError: {
        DownloadCsvFail: 'Unable to download csv file.',
        Message: 'Unable to perform availability check',
        END_DATE_IS_IN_THE_PAST: 'Line item end date is in the past',
        START_DATE_IS_INVALID: 'Line item start date is invalid (ie. in the past)',
        CANNOT_AMEND_ENDED_PROPOSAL_ITEM: 'Cannot amend Ended line item',
        END_DATE_BEFORE_START_DATE: 'Line item end date cannot be before start date',
        OUTSIDE_ACCEPTABLE_DATE_RANGE:
            'Line item dates are outside acceptable date range (ie. in the past)',
        STATUS_NOT_AMENDABLE: 'Line item status does not allow amendment',
        TYPE_OF_BUY_NOT_SUPPORTED: 'Line item type of buy does not allow amendment',
    },
    ACFooter: {
        Book: 'Book',
        Download: 'Download',
        Cancel: 'Cancel',
        Hold: 'Hold',
        Rebook: 'Rebook',
        RequestHold: 'Request Hold',
        RequestRenewHold: 'Request Renew Hold',
        ApproveHold: 'Approve Hold',
        ApproveRenewal: 'Approve Renewal',
    },
    ACPIList: {
        EmptyMessage: 'There are no line items in your campaign.',
    },
    ACPIRow: {
        AvailableTitle: 'Available:',
        BlockedByCutoffDateMessage: 'You cannot book after the cut-off date ({{cutoffDate}})',
        BlockingProposalLink: 'See blocking campaigns',
        BookRemainingTime: 'Only the remaining time will be booked',
        CountFace: '{{faceCount}} face',
        CountFaces: '{{faceCount}} faces',
        CountGroup: '{{groupCount}} group',
        CountGroups: '{{groupCount}} groups',
        CountScreen: '{{screenCount}} screen',
        CountScreens: '{{screenCount}} screens',
        notDeployedMessageDigitalPlural:
            '{{screenCount}} screens are not yet deployed ({{deploymentDate}})',
        notDeployedMessageDigitalSingular:
            '{{screenCount}} screen is not yet deployed ({{deploymentDate}})',
        notDeployedMessageStaticPlural:
            '{{screenCount}} faces are not yet deployed ({{deploymentDate}})',
        notDeployedMessageStaticSingular:
            '{{screenCount}} face is not yet deployed ({{deploymentDate}})',
        OutsideOpeningHours: 'One or more entered selections do not fit the schedule.',
        PartiallyReachable: 'Partially reachable: {{percentage}}%',
        Unreachable: 'Unreachable',
        PartialOpeningHours: 'Part of the selected time period is outside hours of operation',
        PartialTitle: 'Partially available:',
        ProposalWillBeAffected: 'This Takeover will affect some campaigns',
        PushedProposalLink: 'See the campaigns that will be pushed',
        SelectDifferentPeriod: 'Use a different selection or booking period',
        TakeoverBlockedByTakeoverMessage: 'The takeover is blocked by another takeover',
        HasBlockingPreemptibleProposalItems: 'Cannot be booked/held due to blocking daypart',
        UnavailableTitle: 'Unavailable:',
        YouCanProceed: 'You can proceed',
    },
    ACDetail: {
        PushedTitle: 'Line items that will be pushed',
        BlockingTitle: 'Blocking line items',
        GoBack: '← Go Back',
        TakeoverBlockingTitle: 'Blocking takeovers',
        LabelName: 'Line item name',
        LabelStatus: 'Status',
        LabelStartDate: 'Start date',
        LabelEndDate: 'End date',
        LabelFaces: 'Affected / Total Faces',
        LabelScreens: 'Affected / Total Screens',
        LabelPrice: 'Price',
        LabelPriority: 'Priority',
        LabelOwner: 'Owner',
        LblReasonApprove: 'Additional Information for hold approver:',
        LblReasonApproveRenew: 'Additional Information for renewal approver:',
    },
    ACHeader: {
        ACHeaderPreparingBook: 'Preparing to book campaign',
        ACHeaderPreparingDownload: 'Preparing to download campaign',
        ACHeaderPreparingHold: 'Preparing to hold campaign',
        ACHeaderPreparingRequestHold: 'Request a hold on this campaign',
        ACHeaderPreparingApproveHold: 'Preparing to approve hold request',
        ACHeaderPreparingApproveRenewal: 'Preparing to approve renewal request',
    },
};
